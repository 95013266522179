<template>
  <transition appear @before-enter="beforeEnter" @enter="enter">
    <Disclosure as="nav" class="sticky top-0 z-30 bg-[#FEFAF1] xl:flex justify-center" v-slot="{ open }">
      <div class="px-[24px] md:px-0 mx-auto md:w-[82vw] header-wrap">
        <div class="relative py-3">
          <!-- Mobile menu button -->
          <div class="absolute inset-y-0 right-0 flex items-center xl:hidden">
            <DisclosureButton
              class="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
            >
              <Bars3Icon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
              <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true" />
            </DisclosureButton>
          </div>

          <div class="flex items-center justify-between">
            <!-- Logo -->
            <div class="flex items-center">
              <router-link to="/">
                <img class="h8 w-[185px] lg:block" src="@/assets/images/logo.png" alt="CareerBuddy" />
              </router-link>
            </div>

            <!-- Desktop Navigation -->
            <div class="hidden sm:ml-6 xl:block">
              <div class="flex items-center space-x-4">
                <a href="/talents" class="px-4 py-2 text-[14px] text-gray-600 rounded-md group relative">
                  <span class="relative z-10 group-hover:text-brand-black transition-colors duration-200">For Talents</span>
                  <div
                    class="absolute inset-0 scale-100 opacity-0 group-hover:opacity-100 transition-all duration-200 rounded-md bg-brand-primary/5 shadow-[0_0_0_1px_rgba(255,36,73,0.1)] group-hover:shadow-[0_0_0_1px_rgba(255,36,73,0.2),0_1px_2px_rgba(255,36,73,0.1)]"
                  ></div>
                </a>

                <a href="/employer" class="px-4 py-2 text-[14px] text-gray-600 rounded-md group relative">
                  <span class="relative z-10 group-hover:text-brand-black transition-colors duration-200">For Companies</span>
                  <div
                    class="absolute inset-0 scale-100 opacity-0 group-hover:opacity-100 transition-all duration-200 rounded-md bg-brand-primary/5 shadow-[0_0_0_1px_rgba(255,36,73,0.1)] group-hover:shadow-[0_0_0_1px_rgba(255,36,73,0.2),0_1px_2px_rgba(255,36,73,0.1)]"
                  ></div>
                </a>

                <router-link to="/blog" class="px-4 py-2 text-[14px] text-gray-600 rounded-md group relative">
                  <span class="relative z-10 group-hover:text-brand-black transition-colors duration-200">Blog</span>
                  <div
                    class="absolute inset-0 scale-100 opacity-0 group-hover:opacity-100 transition-all duration-200 rounded-md bg-brand-primary/5 shadow-[0_0_0_1px_rgba(255,36,73,0.1)] group-hover:shadow-[0_0_0_1px_rgba(255,36,73,0.2),0_1px_2px_rgba(255,36,73,0.1)]"
                  ></div>
                </router-link>

                <router-link to="/referral" class="px-4 py-2 text-[14px] text-gray-600 rounded-md group relative">
                  <span class="relative z-10 group-hover:text-brand-black transition-colors duration-200">Refer & Earn</span>
                  <div
                    class="absolute inset-0 scale-100 opacity-0 group-hover:opacity-100 transition-all duration-200 rounded-md bg-brand-primary/5 shadow-[0_0_0_1px_rgba(255,36,73,0.1)] group-hover:shadow-[0_0_0_1px_rgba(255,36,73,0.2),0_1px_2px_rgba(255,36,73,0.1)]"
                  ></div>
                </router-link>
              </div>
            </div>
            <!-- Desktop Auth Buttons -->
            <div class="hidden xl:inline-block">
              <button
                class="mr-4 px-6 py-3 text-brand-black text-base font-bold"
                @click="
                  overlay = true;
                  overlayType = 'signup';
                "
              >
                Log in
              </button>
              <button
                class="mr-4 px-6 py-3 border border-brand-black bg-brand-black text-white text-base font-bold rounded-xl"
                @click="
                  overlay = true;
                  overlayType = 'login';
                "
              >
                Get Started
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Mobile Navigation Menu -->
      <DisclosurePanel class="bg-white absolute left-0 top-[48px] right-0 xl:hidden">
        <div class="space-y-1 px-2 pt-2 pb-3 w-auto md:w-[82vw] mx-auto">
          <a href="/talents" class="mx-3 py-2 text-[16px] w-full flex justify-between text-black/60 hover:text-dark"> For Talents </a>
          <a href="/employer" class="mx-3 py-2 text-[16px] w-full flex justify-between text-black/60 hover:text-dark"> For Companies </a>
          <router-link to="/blog" class="mx-3 py-2 text-[16px] w-full flex justify-between text-black/60 hover:text-dark">Blog</router-link>
          <router-link to="/referral" class="mx-3 py-2 text-[16px] w-full flex justify-between text-black/60 hover:text-dark">
            Refer & Earn
          </router-link>

          <br />

          <div class="flex items-center px-3 my-8">
            <Button
              label="Sign up"
              color="black"
              class="ml12"
              @click="
                overlay = true;
                overlayType = 'signup';
              "
            />
            <Button
              label="Log in"
              color="black"
              outline
              class="ml-4"
              @click="
                overlay = true;
                overlayType = 'login';
              "
            />
          </div>

          <br />
        </div>
      </DisclosurePanel>
    </Disclosure>
  </transition>
  <Overlay v-if="overlay" :type="overlayType" :employer-url="employer_url" :candidate-url="candidate_url" @hide-overlay="overlay = false" />
</template>

<script setup>
import { onMounted, ref, computed } from 'vue';
import Overlay from '@/components/partials/AccountOverlay.vue';
import Button from '@/components/Button.vue';
import Nav from '@/assets/icons/nav.svg?inline';
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/vue/24/outline';
import { useRoute } from 'vue-router';
import gsap from 'gsap';

const route = useRoute();
const candidate_url = ref(process.env.VUE_APP_CANDIDATE_URL);
const employer_url = ref(process.env.VUE_APP_EMPLOYER_URL);
const overlay = ref(false);
const overlayType = ref('login');

const setCurrent = (value) => {
  return route.path === value;
};

const navigation = ref([
  { name: 'For Talents', href: 'https://candidate.thecareerbuddy.com/' },
  { name: 'For Companies', href: 'https://employer.thecareerbuddy.com/' },
  { name: 'Blog', href: '/blog', current: setCurrent('/blog') },
  { name: 'Refer & Earn', href: '/referral', current: setCurrent('/referral') },
]);
</script>

<script>
export default {
  data() {
    return {
      showNav: false,
    };
  },
  methods: {
    toggleNav() {
      this.showNav = !this.showNav;
    },
    beforeEnter(el) {
      el.style.opacity = '0';
      el.style.transform = 'translateY(-100px)';
    },
    enter(el) {
      gsap.to(el, {
        duration: 3,
        y: 0,
        opacity: 1,
        ease: 'back.out',
      });
    },
  },
};
</script>

<style scoped>
.router-link-exact-active {
  color: #1a1a1a;
  font-weight: 500;
}
</style>
